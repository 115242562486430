import React from 'react'
import { Link } from 'gatsby'

import WorkFeature from './work-feature'

import styles from './work-feature-list.module.css'

function WorkFeatureList(props) {
  return (
    <div className={styles.root}>
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node) => (
            <li key={node.id}>
              <WorkFeature
                title={node.title}
                subtitle={node.excerpt}
                backgroundColor={node.headerBgColor.hex}
                color={node.headerTitleColor.hex}
                links={node.headerLinks}
                linkColor={node.headerLinkColor}
                forceFullWidth={node.forceFullWidth}
                featureVisual={
                  node.headerImage && node.headerImage.image ? node.headerImage : node.headerIframe
                }
                isLink={true}
              />
            </li>
          ))}
      </ul>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  )
}

WorkFeatureList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
}

export default WorkFeatureList
